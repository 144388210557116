<template>
  <b-card>
    <template #header>
      <div>
        <h1>{{ task.name }}</h1>
        <small>{{ task.description }}</small>
        <b-card-sub-title class="mt-1">Liste des activités</b-card-sub-title>
      </div>

      <div>
        <b-row>
          <b-col md="auto" style="margin-top: 7px">
            <p
              id="alert-activity"
              icon="AlertTriangleIcon"
              size="20"
              class="anim"
            >
              ?
            </p>
          </b-col>
          <b-col md="auto">
            <b-button v-b-modal.modal-primary>Ajouter une activité</b-button>
          </b-col>
        </b-row>
        <b-tooltip variant="light" target="alert-activity" triggers="hover">
          Veuillez ajouter des activités pour décrire les travaux menés sur
          cette tâche
        </b-tooltip>
        <b-modal
          no-close-on-esc
          no-close-on-backdrop
          id="modal-primary"
          :ok-title="modalButtonTitle"
          ok-variant="primary"
          cancel-title="Annuler"
          cancel-variant="danger"
          @ok="addOrEditChangeLog"
          @cancel="abortAction"
          centered
          hide-header-close
          modal-class="modal-primary"
          :title="modalTitle"
        >
          <validation-observer ref="form">
            <b-form>
              <validation-provider :rules="'required'" #default="{ errors }">
                <b-form-textarea
                  id="textarea-default"
                  v-model="changelog.title"
                  placeholder="Libellé de l'activité"
                  rows="3"
                />
                <small class="help-text text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal
          no-close-on-esc
          no-close-on-backdrop
          id="delete-modal"
          ok-title="Supprimer"
          ok-variant="primary"
          cancel-title="Annuler"
          cancel-variant="danger"
          @ok="removeThisChangeLog"
          @cancel="abortAction"
          centered
          hide-header-close
          modal-class="modal-primary"
          title="Attention"
        >
          <p>
            Voulez-vous vraiment supprimer cette activité ? <br />
            Cette action est irréversible.
          </p>
          <!-- <validation-observer ref="form">
            <b-form>
              <validation-provider :rules="'required'" #default="{ errors }">
                <b-input
                  placeholder="Libellé de l'activité"
                  v-model="changelog.title"
                ></b-input>
                <small class="help-text text-danger">{{ errors[0] }}</small>
                <b-textarea
                  class="mt-1"
                  no-resize
                  placeholder="Description de l'activité"
                  v-model="changelog.description"
                ></b-textarea>
              </validation-provider>
            </b-form>
          </validation-observer> -->
        </b-modal>
      </div>
    </template>
    <hr />
    <h2 v-if="changelogs.length == 0">Aucune activité sur cette tâche.</h2>
    <app-timeline>
      <app-timeline-item
        v-for="item in changelogs"
        :key="item.id"
        :title="item.title"
        :subtitle="item.description"
        :time="_time(item.created_at)"
        :withActions="true"
        @edit="
          $bvModal.show('modal-primary');
          getItemToEdit(item);
        "
        @remove="
          $bvModal.show('delete-modal');
          getItemToDelete(item);
        "
        variant="success"
      />
    </app-timeline>

    <!-- <b-modal
      id="edit-modal"
      ok-title="Modifier"
      ok-variant="success"
      ok-only
      @ok="saveForm"
      centered
      cancel-title="Fermer"
      cancel-variant="warning"
      modal-class="modal-primary"
      title="Modifier cette activité."
    >
      <validation-observer ref="form">
        <b-form>
          <validation-provider :rules="'required'" #default="{ errors }">
            <b-input
              placeholder="Libellé de l'activité"
              v-model="activeChangeLog.title"
            ></b-input>
            <small class="help-text text-danger">{{ errors[0] }}</small>
            <b-textarea
              class="mt-1"
              no-resize
              placeholder="Description de l'activité"
              v-model="activeChangeLog.description"
            ></b-textarea>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal> -->
  </b-card>
</template>

<script>
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      changelog: { task_id: null, title: "", description: "" },
      activeChangeLog: {},
      canEdit: false,
      changelogs: [],
      task: { name: "" },
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    KyInput,
  },

  computed: {
    modalButtonTitle() {
      return !this.canEdit ? "Enregistrer" : "Modifier";
    },

    modalTitle() {
      return !this.canEdit ? "Ajouter une activité" : "Modifier cette activité";
    },
  },

  methods: {
    ...mapActions("tasks", [
      "addChangeLog",
      "getChangeLogs",
      "deleteChangeLog",
      "updateChangeLog",
    ]),

    abortAction() {
      this.canEdit = false;
      this.changelog = { title: "", description: "" };
    },

    // async saveForm() {
    //   var valid = await this.$refs.form.validate();

    //   if (!valid) return;
    //   this.changelog.task_id = this.$route.params.id;
    //   this.addChangeLog(this.$formData(this.changelog)).then((r) => {
    //     this.push(r.data.data);
    //     this.changelog = { title: "", description: "" };
    //   });
    // },

    _time(time) {
      return moment(time).locale("fr").fromNow();
    },

    async getItemToEdit(item) {
      this.changelog = Object.assign({}, item);
      this.canEdit = true;
    },

    async addOrEditChangeLog() {
      var valid = await this.$refs.form.validate();

      if (!valid) return;
      this.changelog.task_id = this.$route.params.id;
      if (this.canEdit) {
        this.changelog.description =
          this.changelog.description != null ? this.changelog.description : "";
        this.updateChangeLog(this.$formData(this.changelog))
          .then(() => {
            this.abortAction();
            this.getChangeLogs(this.$route.params.id).then((r) => {
              this.changelogs = r.data.data;
              this.task = r.data.task;
            });

            this.$bvToast.toast("Activité modifiée.", {
              variant: "primary",
              solid: true,
              title: "Message",
            });
          })
          .catch((e) => {
            this.$bvToast.toast("Echec de suppression", {
              variant: "danger",
              solid: true,
              title: "Erreur",
            });
          });
      } else {
        this.changelog.task_id = this.$route.params.id;
        this.addChangeLog(this.$formData(this.changelog))
          .then((r) => {
            this.changelogs.push(r.data.data);
            this.changelog = { title: "", description: "" };
            this.$bvToast.toast("Activité ajoutée.", {
              variant: "primary",
              solid: true,
              title: "Message",
            });
          })
          .catch((e) => {
            this.$bvToast.toast("Echec de suppression", {
              variant: "danger",
              solid: true,
              title: "Erreur",
            });
          });
      }
    },

    getItemToDelete(item) {
      this.activeChangeLog = Object.assign({}, item);
    },

    async removeThisChangeLog() {
      this.deleteChangeLog(this.activeChangeLog.id)
        .then(() => {
          this.activeChangeLog = {};
          this.getChangeLogs(this.$route.params.id).then((r) => {
            this.changelogs = r.data.data;
            this.task = r.data.task;
          });

          this.$bvToast.toast("Activité supprimée.", {
            variant: "primary",
            solid: true,
            title: "Message",
          });
        })
        .catch((e) => {
          this.$bvToast.toast("Echec de suppression", {
            variant: "danger",
            solid: true,
            title: "Erreur",
          });
        });
    },
  },

  mounted() {
    this.getChangeLogs(this.$route.params.id).then((r) => {
      this.changelogs = r.data.data;
      this.task = r.data.task;
    });
  },
};
</script>

<style scoped>
.anim {
  border: 1px solid;
  border-radius: 50px;
  padding: 2px;
  padding-right: 8px;
  padding-left: 8px;
  animation: anime 2s linear infinite;
  cursor: pointer;
}
@keyframes anime {
  0% {
    color: rgb(255, 145, 0);
  }
  50% {
    color: rgb(255, 230, 202);
  }
  100% {
    color: rgb(255, 153, 0);
  }
}
</style>
